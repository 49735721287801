import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Link from '../Link'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Pagină inexistentă" />
    <h1>Pagina nu a fost găsită.</h1>
    <p>
      Te rugăm să te întorci{' '}
      <Link color="red" bg="none" bgOver="none" to="/">
        acasă
      </Link>
      .
    </p>
  </Layout>
)

export default NotFoundPage
